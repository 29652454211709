@import '__importable.scss';
.container {
	background-color: $white;
	border-radius: $radius-7 $radius-7 0 0;
	padding: 6.4rem 1.6rem;
	width: 100%;
	margin-top: -3.2rem;
	max-width: 43rem;
	position: relative;
	z-index: $zindex-1;

	@media screen and (min-width: $media-sm) {
		border-radius: $radius-7;
		margin-top: 0;
	}
	
}
.form {
	display: flex;
	padding-top: 1.6rem;
	flex-direction: column;
	align-items: center;
	gap: 0.8rem;
	align-self: stretch;
	.input{
		margin-top: 1.8rem;
		margin-bottom: 0;
		input::-webkit-outer-spin-button,
		input::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}

		input[type=number] {
			-moz-appearance: textfield;
		}
	}
}

.loginHeader {
	margin-bottom: 2.4rem;
	margin-top: 2.4rem; 
	font-size: 4rem
}

.resetPassword {
	@extend .loginHeader;
	margin-top: 3.2rem;
}

.buttons {
	display: flex;
	padding-top: 1.6rem;
	flex-direction: column;
	align-items: center;
	gap: 0.8rem;
	align-self: stretch;
	button {
		height: 4.8rem;
	}
}

.backLink {
	position: absolute;
	top: 1.6rem;
	left: 1.6rem;
	font-size: 1.4rem;
}

.subheader{
	color: $gray-5;
	text-align: center;
	font-size: 1.6rem;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
}

.footer{
	display: flex;
	padding-top: 1.6rem;
	flex-direction: column;
	align-items: center;
	gap: 0.8rem;
	align-self: stretch;
	.footer-text{
		color: $gray-5;
		text-align: center;
		font-family: var(--poppins);
		font-size: 1.4rem;
		font-style: normal;
		font-weight: 400;
		line-height: 146%;
	}
}

.notification {
	margin: 0.8rem 0;
	color: $orange-3;
	svg {
		fill: $orange-3 !important;
	}
}